
// This component is used as a wrapper

import auth from '@/mixins/auth';
import mixins from '@/utils/mixins';

import { subscriptionProvider } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib';
import { BillingSubscription } from '@vue-altoleap-libraries/vue-altoleap-accounts-lib/types/model/subscription';
import * as Sentry from '@sentry/vue';

// to handle language based routing.
export default mixins(auth).extend({
  name: 'LayoutsRoot',
  components: {
    LayoutFreeTrialBar: () => import('./FreeTrialBar.vue'),
    LayoutAppBar: () => import('./AppBar.vue'),
    LayoutDrawer: () => import('./Drawer.vue'),
    LayoutRightDrawer: () => import('./RightDrawer.vue'),
    LayoutFab: () => import('./Fab.vue'),
    LayoutView: () => import('./View.vue')
  },

  data() {
    return {
      appLoading: false
    };
  },

  computed: {
    subscriptionProvider(): any {
      return subscriptionProvider(this.$store);
    },

    subscription(): BillingSubscription {
      return this.subscriptionProvider.getSubscription;
    }
  },

  beforeDestroy() {
    Sentry.configureScope((scope) => {
      scope.clear();
    });
  },

  async created() {
    try {
      if (!this.getCurrentUser.id) {
        this.appLoading = true;
        await this.fetchMe();
      }

      // fetch for drawer if there is no subscription
      if (!this.isUserSuperuser) {
        if (!this.subscription.id) {
          this.appLoading = true;
          await this.subscriptionProvider.fetchSubscription();
        }
      }

      // Set user information to be sent to Sentry
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: this.getCurrentUser.id,
          email: this.getCurrentUser.email,
          organization: this.getCurrentUser.organization
        });
      });
    } catch (e) {
      // TODO: LH 2021-10-05 maybe just logout
    } finally {
      this.appLoading = false;
    }
  }
});
